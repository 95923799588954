
import { defineComponent } from 'vue'
import ShowSecret from '../components/ShowSecret.vue'

export default defineComponent({
  name: 'Home',
  components: {
    ShowSecret
  }
})
