
import { defineComponent } from 'vue'
import { Service, OpenAPI } from '../api'
import { Encryption } from '../crypto/encryption'
import { EncodingUrlSafe } from '../crypto/encoding-url-safe'
import copy from 'copy-to-clipboard'

export default defineComponent({
  name: 'EnterSecret',
  data () {
    return {
      password: '',
      ttlAmountDays: 30,
      ttlOptions: [
        { value: 1, label: "24 Hours" },
        { value: 7, label: "7 Days" },
        { value: 30, label: "30 Days" },
        { value: 90, label: "90 Days" },
        { value: 365, label: "365 Days" },
        { value: -1, label: "Unlimited" },
      ],
      ttlUnit: 'Days',
      maxRevielCount: 1,
      maxRevielOptions: [
        { value: 1, label: "Only once" },
        { value: 3, label: "3 Times" },
        { value: 10, label: "10 Times" },
        { value: -1, label: "Unlimited" },
      ],
      generatedLink: '',
      copied: false,
    }
  },
  methods: {
    async generateLink () {
      OpenAPI.BASE = '/api/v1'

      try {

      const key = await Encryption.randomKey()
      const ecnrypted = await Encryption.encrypt(this.password, key)

      const resp = await Service.createSecret({
        encryptedSecret: ecnrypted.encrypted,
        initializationVector: ecnrypted.initializationVector,
        timeToLive: this.ttlAmountDays > 0 ? this.ttlAmountDays * 24 * 60 * 60 : -1,
        maxRetrievalCount: this.maxRevielCount,
      })

      const keyBytes = await Encryption.exportKey(key)
      const keyString = EncodingUrlSafe.encode(keyBytes)

      this.generatedLink = window.location.protocol + '//' + window.location.host + '/sec/' + resp.id + '#' + keyString

      } catch (ex) {
        console.log( "ex ", ex)
      }
    },
    copyLink() {
      if (this.generatedLink && this.generatedLink !== '') {
        this.copied = copy(this.generatedLink)
        console.log("copy success: ", this.copied)
      }
    }
  }
})
