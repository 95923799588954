
import { defineComponent } from "@vue/runtime-core"

export default defineComponent({
  name: 'EnterSecret',
  data () {
    return {
      items: [
        {
          label: 'Sharepass',
          visible: false
        },
      ]
    }
  },
  methods: {
  }
})
