/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSecretRequest } from '../models/CreateSecretRequest';
import type { Secret } from '../models/Secret';
import type { SecretMetadata } from '../models/SecretMetadata';
import { request as __request } from '../core/request';

export class Service {

    /**
     * @param requestBody
     * @returns SecretMetadata Secret created
     * @throws ApiError
     */
    public static async createSecret(
        requestBody: CreateSecretRequest,
    ): Promise<SecretMetadata> {
        const result = await __request({
            method: 'POST',
            path: `/secrets`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id ID of pet to fetch
     * @returns SecretMetadata OK
     * @throws ApiError
     */
    public static async getSecretMetadata(
        id: string,
    ): Promise<SecretMetadata> {
        const result = await __request({
            method: 'GET',
            path: `/secrets/${id}`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * @param id ID of pet to fetch
     * @returns Secret Secret created
     * @throws ApiError
     */
    public static async revealSecret(
        id: string,
    ): Promise<Secret> {
        const result = await __request({
            method: 'POST',
            path: `/secrets/${id}/reveal`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

}